import { graphql, Link } from "gatsby";
import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Seo from "../components/seo";
import EventBanner from "../components/EventBanner/EventBanner";
import { Container, Row, Col, Modal, Card } from "react-bootstrap";
import moment from "moment";
import "../components/NewsDetails/NewsDetails.scss";
import '../components/Events/Events.scss'
import EventbritePopupCheckout from "../components/EventBrite";
import Bookcard from "../components/BookCard/BookCard";
import EventsSubscription from "../components/forms/events-subscribe";
import $ from "jquery";

const NewsVideosTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.event;
    var events = props.data.glstrapi?.events;
    const module = props.data.glstrapi?.globalModule;
    const [show, setShow] = React.useState(false);
    useEffect(() => {
        $('html, body').animate({
            scrollTop: 0
        }, 0)

    }, []);
    events = events.sort((a, b) => moment(a.Date).valueOf() - moment(b.Date).valueOf())
    events = events.filter(evnt => moment(evnt.Date).isAfter()).slice(0,3);

    return (
        <React.Fragment>
            <Seo
                title={GQLPage.Meta_Title}
                description={GQLPage.Meta_Description}
                location={props.location}
            />
            <Header menuLinks={[]} />
            <div className="newsdetails-page">
                <EventBanner
                    data={GQLPage}
                />
            </div> 
            {events?.length > 0 &&
                <React.Fragment>
                    <hr />
                    <section className="events">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <p className="event-subhead">other events that may interest you...</p>
                                </Col>
                                <Col xs={12}>
                                    <div className="events-block">
                                        {events?.map((item, index) => {

                                            return (
                                                <div className="event-wrap" key={index}>
                                                    <div className="img-wrap img-zoom">
                                                        <img src={item.Tile_Image?.url} alt={item.Tile_Image?.alternativeText ? item.Tile_Image?.alternativeText : item.Title + " - tlc Estate Agents"} />
                                                    </div>
                                                    <div className="event-info">
                                                        <h2><Link to={"/local-living/events/" + item.Url + "/"}>{item.Title}</Link></h2>
                                                        <div className="event-cta">
                                                            <div className="event-date">
                                                                <div className="wrap-icon">
                                                                    <i className="icon-calendar"></i>
                                                                </div>
                                                                {moment(item.Date).format("DD/MM/YYYY")}
                                                            </div>
                                                            <div className="book-ticket">
                                                                <a>
                                                                    <EventbritePopupCheckout ebEventId={item.EventId} className={moment().isAfter(item.Date) ? "" : "cursor-pointer"} disabled={moment().isAfter(item.Date)} event_item={item}>
                                                                        <div className="wrap-icon">
                                                                            <i className="icon-ticket"></i>
                                                                        </div>
                                                                        <span>Book Tickets</span>
                                                                    </EventbritePopupCheckout>
                                                                </a>
                                                            </div>
                                                            <div className="view-detail">
                                                                <Link to={"/local-living/events/" + item.Url}>
                                                                    <div className="wrap-icon">
                                                                        <i className="icon-lines"></i>
                                                                    </div>
                                                                    <span className="md">Details</span>
                                                                    <span className='dd'>View Details</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )

                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <Container>
                        <Bookcard
                            bookcardTitle="Receive notifications of events in Kensington and Chelsea"
                            bookcardContent="From music concerts, cinema nights and dog parties to theatre productions, we have something for everyone"
                            bookcardBtn="register with us"
                            eventPopup={true}
                            openEventForm={() => setShow(true)}
                            column={false}
                        />
                    </Container>
                </React.Fragment>
            }
            <Modal className="form-popup" onHide={() => setShow(false)} show={show} contentClassName="p-0" size="sm">
                <Card className="border-0">
                    <Card.Img variant="top" src={module?.Events_Subscribe_Form?.Card_Image?.url} />
                    <Card.Header className="px-5 pt-4 border-0 bg-transparent position-relative">
                        <Card.Title>{module?.Events_Subscribe_Form?.Title}</Card.Title>
                        <button onClick={() => setShow(false)} type="button" className="close-modal">
                            <i className="icon-close-modal"></i>
                        </button>
                    </Card.Header>
                    <Card.Body className="px-5">
                        <EventsSubscription
                            email={module?.Events_Subscribe_Form?.To_Email}
                            bcc={module?.Events_Subscribe_Form?.To_Bcc}
                        />
                    </Card.Body>
                </Card>
            </Modal>
            <Footer
                popularSearch="Popular_About" showBreadcrumb = {true}
            />
        </React.Fragment>
    )
}

export default NewsVideosTemplate;


export const pageQuery = graphql`
    query eventsQuery($id: ID!) {
        glstrapi {
            event(id: $id, publicationState: LIVE) {
                _id
                Title
                Description
                Alias
                Address
                Date
                From
                EventId
                Meta_Description
                Meta_Title
                Publish
                Sort
                To
                Url
                Video_Url
                Tile_Image {
                    alternativeText
                    url
                    url_sharp {
                        childImageSharp {
                            gatsbyImageData(
                                formats: AUTO
                                width: 1360
                                height: 1040
                                placeholder: BLURRED
                                quality: 100
                                transformOptions: {
                                    cropFocus: CENTER, fit: COVER
                                }
                            )
                        }
                    }
                }
                imagetransforms
            }
            events(where: { Publish: true }) {
                _id
                Title
                Alias
                Date
                From
                EventId
                Publish
                To
                Url
                Video_Url
                Tile_Image {
                    alternativeText
                    url
                    url_sharp {
                        childImageSharp {
                            gatsbyImageData(
                                formats: AUTO
                                width: 1360
                                height: 1040
                                placeholder: BLURRED
                                quality: 100
                                transformOptions: {
                                    cropFocus: CENTER, fit: COVER
                                }
                            )
                        }
                    }
                }
                imagetransforms
            }
            globalModule {
                Book_A_Appointment {
                    _id
                    Book_Title
                    Description
                    Cta_Label
                    Cta_Link {
                        _id
                        URL
                    }
                }
                Lets_Work_Together {
                    _id
                    Title
                    Sm_Title
                    Cta_1_Label
                    Cta_2_Label
                    Cta_1_Link {
                        URL
                        _id
                    }
                    Cta_2_Link {
                        URL
                        _id
                    }
                }
                Events_Subscribe_Form {
                    Title
                    Description
                    Card_Image {
                        alternativeText
                        url
                    }
                }
            }
        }
    }
`
