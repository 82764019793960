import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import france from "../../images/france.png";
import china from "../../images/china.png";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import HTMLReactParser from "html-react-parser";
import "./EventBanner.scss";
import moment from "moment";
// import EventbritePopupCheckout from "../EventBrite";
import _ from "lodash";
import BookYourTicket from "./BookYourTicket";
import PlayVideo from "../PlayVideo/PlayVideo";

// Header component

const EventBanner = (props) => {
    const [isPlaying, setPlaying] = useState(false);
    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Tile_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Tile_Image_Transforms;
    }
    return (
        <React.Fragment>
            <section className="event-bio">
                <Container>
                    <Row>
                        <Col md={12} className="bio-wrapper">
                            <div className="bio-img">
                                <ImageTransform
                                    imagesources={props?.data?.Tile_Image?.url}
                                    renderer="pic-src" imagename="careers.Tile_Image.banner"
                                    attr={{ alt: props?.data?.Tile_Image?.alternativeText, className: 'm-0' }}
                                    imagetransformresult={processedImages}
                                    id={props?.data?._id}
                                />
                                {/* {props?.data?.Video_Url && <i className="video-icon" onClick={() => { setPlay(true) }}></i>} */}
                                {(props?.data?.Video_Url && !isPlaying) &&
                                    <React.Fragment>
                                        <i
                                            className="video-icon"
                                            onClick={async () => {
                                                setPlaying(true);
                                            }}
                                        />
                                    </React.Fragment>
                                }
                                {(props?.data?.Video_Url && isPlaying) && <div className="inline-video inline-video-container">
                                    {isPlaying &&
                                        <React.Fragment>
                                            <div className="d-block">
                                                <PlayVideo
                                                    isOpen={isPlaying}
                                                    videoId={props?.data?.Video_Url}
                                                    stopPlay={setPlaying}
                                                    width={'100%'} height={'100%'}
                                                    fullscreen={true}
                                                />
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>}
                            </div>

                            <div className="bio-details-wrapper ">
                                <div className="bio-details">
                                    <h1>{props?.data?.Title}</h1>
                                    <p className="sm-info">{moment(props?.data?.Date).format("Do MMM YYYY")}</p>
                                    <p className="sm-info">{moment(props?.data?.From).format("LT")} - {moment(props?.data?.To).format("LT")}</p>
                                    <p className="sm-info">{props?.data?.Address}</p>
                                    <div className="contact-info">
                                        {/* <EventbritePopupCheckout ebEventId={props?.data?.EventId} className={moment().isAfter(props?.data?.Date) ? "" : "cursor-pointer"} disabled={moment().isAfter(props?.data?.Date)}>
                                            <button type="button" className="btn d-block" disabled={moment().isAfter(props?.data?.Date)}>
                                                Book your Ticket
                                            </button>
                                        </EventbritePopupCheckout> */}

                                        {typeof window !== "undefined" && !_.isEmpty(props.data.EventId) &&
                                            <BookYourTicket data={props?.data} className="btn d-block" btnLabel="Book Your Ticket" />
                                        }

                                    </div>
                                    <div className="top-content">
                                        {props?.data?.Description !== null && <div className="content-wrapper">
                                            {HTMLReactParser(props?.data?.Description)}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default EventBanner;
